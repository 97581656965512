import React, { lazy, Suspense } from "react"
import { Grid, Container } from "@mui/material"
import LoadingComponent from "../components/LoadingComponent/LoadingComponent"

const ChatWithUs = lazy(() => import("../components/ContactUs/ChatWithUs"))
const ContactForm = lazy(() => import("../components/ContactUs/ContactForm"))
const ContributingSection = lazy(() =>
  import("../components/AboutUs/Contribute/ContributingSection")
)
const TrainingSection = lazy(() =>
  import("../components/TrainingSection/TrainingSection")
)
const ContributeBackgroundImage = lazy(() =>
  import("../images/ContributeBG.png")
)

function ContactUsLayout() {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                marginY: { xs: 4, sm: 5, md: 10 },
              }}
            >
              <Grid item xs={12} sm={6}>
                <ChatWithUs />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "grid", placeItems: "end" }}
              >
                <ContactForm isInContactPage={true} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          sx={{
            backgroundImage: `url(${ContributeBackgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          item
          xs={12}
        >
          <ContributingSection />
        </Grid>
        <Grid item xs={12}>
          <TrainingSection />
        </Grid>
      </Grid>
    </Suspense>
  )
}

export default ContactUsLayout
