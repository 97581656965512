import React from "react"
import Layout from "../components/Layout/Layout"
import ContactUsLayout from "../layouts/ContactUsLayout"
import { Helmet } from "react-helmet"
function ContactForm() {
  return (
    <Layout>
      <Helmet>
        <title>Contact - Cassandra Link</title>
        <meta property="og:title" content="Contact - Cassandra Link" />
        <meta name="description" content="Get in touch with Cassandra Link" />
        <meta
          property="og:description"
          content="Get in touch with Cassandra Link"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Contact - Cassandra Link",
            keywords: "Cassandra Link, Contact",
            author: {
              "@type": "Organization",
              name: "Cassandra Link, Cassandra, Contact",
            },
          })}
        </script>

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Contact - Cassandra Link"} />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
      </Helmet>
      <ContactUsLayout />
    </Layout>
  )
}

export default ContactForm
